import React from "react"
import "./style.scss"

const Button = ({ children, extraClass, show = true, ...props }) => {
  if (!show) {
    return <></>
  }
  return (
    <button className={`button ${extraClass}`} {...props}>
      {children}
    </button>
  )
}

export { Button }
