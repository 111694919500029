import {
  ON_GET_ORDER_TYPES_SUCCESS,
  ON_GET_ORDER_TYPES_FAILURE,
} from "../actions/types";

const EMPTY_ERROR = {
  isError: false,
  errorType: 0,
  errorMessage: "",
};

const DEFAULT_ERROR = {
  isError: true,
  errorType: 1,
  errorMessage: "DEFAULT_ERROR",
};

const INIT_STATE = {
  orderTypesList: [],
  error: EMPTY_ERROR,
};

const OrderTypeReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_GET_ORDER_TYPES_SUCCESS:
      return {
        orderTypesArr: setOrderTypesEnumByLang(action.payload),
        error: EMPTY_ERROR,
      };
    case ON_GET_ORDER_TYPES_FAILURE:
      return {
        orderTypesList: [],
        error: DEFAULT_ERROR,
      };
    default:
      return state;
  }
};

const setOrderTypesEnumByLang = (obj, lang) => {
  const items = [];
  for (const [key, value] of Object.entries(obj)) {
    switch (lang) {
      case "en":
        items[value.id] = value.orderTypeName;
        break;
      case "he":
        items[value.id] = value.orderTypeName_he;
        break;
      default:
        items[value.id] = value.orderTypeName_he;
    }
  }
  return items;
};

export default OrderTypeReducer;
