import axios from "axios"
import api from "../api/BaseApi"
import {
  ON_GET_BARCODE_SUCCESS,
  ON_GET_BARCODE_FAIL,
  ON_CLEAR_BARCODE,
  ON_LOAD_BARCODE,
  ON_ADD_TO_PRINT_LIST,
  ON_REMOVE_FROM_PRINT_LIST,
  ON_GET_BARCODE_LIST_SUCCESS,
  ON_GET_BARCODE_LIST_FAIL,
  ON_LOAD_BARCODE_LIST,
  CLEAR_BARCODE_LIST,
  CLEAR_ORDER_IDS_FOR_PRINT,
} from "./types"

export const onLoadBarcodePDF = () => {
  return (dispatch) => {
    dispatch({
      type: ON_LOAD_BARCODE,
    })
  }
}

export const clearBarcodePDF = () => {
  return (dispatch) => {
    dispatch({
      type: ON_CLEAR_BARCODE,
    })
  }
}

export const getBarcodePDF = (packageNumber) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
      responseType: "blob",
    }
    axios
      .get(api.getBarcodePDF(packageNumber), options)
      .then((response) => {
        dispatch({
          type: ON_GET_BARCODE_SUCCESS,
          payload: window.URL.createObjectURL(response.data),
        })
      })
      .catch((error) => {
        console.log("BarcodeActions Error: " + error)
        dispatch({
          type: ON_GET_BARCODE_FAIL,
          payload: null,
        })
      })
  }
}

export const addToPrintList = (packageNumber, orderNumber) => {
  return (dispatch) => {
    dispatch({
      type: ON_ADD_TO_PRINT_LIST,
      payload: { packageNumber, orderNumber },
    })
  }
}

export const removeFromPrintList = (packageNumber, orderNumber) => {
  return (dispatch) => {
    dispatch({
      type: ON_REMOVE_FROM_PRINT_LIST,
      payload: { packageNumber, orderNumber },
    })
  }
}

export const getBarcodesPDFList = (packageNumbersList) => {
  return (dispatch) => {
    dispatch({
      type: ON_LOAD_BARCODE_LIST,
    })
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    axios
      .post(api.getBarcodesPDFList, packageNumbersList, options)
      .then((response) => {
        dispatch({
          type: ON_GET_BARCODE_LIST_SUCCESS,
          payload: response.data,
        })
      })
      .catch((error) => {
        console.log("BarcodeActions Error: " + error)
        dispatch({
          type: ON_GET_BARCODE_LIST_FAIL,
          payload: null,
        })
      })
  }
}

export const clearBarcodesPDFList = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_BARCODE_LIST,
    })
  }
}

export const clearOrderIdsForPrint = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ORDER_IDS_FOR_PRINT,
    })
  }
}
