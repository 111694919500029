import React from 'react';

import './style.css';

const H1 = ({ className, title }) => {

    return (
        <h1 className={className}>{title}</h1>
    );
}

export { H1 };
