import React, { Component } from 'react';
import ok from '../../../assets/images/ok.png';
import redx from '../../../assets/images/redx.png';

import './style.scss';
class Message extends Component {
    constructor(props) {
        super(props);
        //this.INIT_STATE = { };
        //this.state = this.INIT_STATE;
    }

    render(){
        const {show} = this.props;
        
        if(show == undefined || !show) {
            return(
                <noscript></noscript>
            );
        }
        const {isError, successText, errorText } = this.props;

        if(isError) {
            return(
                <div className="message-wrapper error" > 
                    <div className="message-content">
                        <div className="message-text">{errorText}</div>
                        <div className="message-icon">
                            <img src={redx} />
                        </div>
                        <div className="message-button" onClick={e => this.props.onClick(e)} tabIndex="0" role="button">סגור</div>
    
                    </div>
                </div>
            );

        }

        return(
            <div className="message-wrapper success" > 
                <div className="message-content">
                    <div className="message-text">{successText}</div>
                    <div className="message-icon"> <img src={ok} /></div>
                    <div className="message-button" onClick={e => this.props.onClick(e)} tabIndex="0" role="button">סגור</div>
                </div>
                
            </div>
        );
    }

}
export default Message;