export const fetchIfEmpty = (list, getFunction) => {
  if (list === undefined || !list || list.length <= 0) {
    if (typeof getFunction === "function") {
      getFunction()
    }
  }
}

export const setEmtyIfNull = (obj) => {
  if (obj == null) {
    return ""
  }
  return obj
}
