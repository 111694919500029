import React, { Component } from "react"
import { connect } from "react-redux"
import Title from "../../common/Title"
import InputField from "../../common/InputField"
import { Button } from "../../common/Button"
import Loader from "../../common/Loader"
import { isValidField } from "../../../utils/FieldsValidation"
import Message from "../../common/Message"
import ValidationMessage from "../../common/ValidationMessage"
import Select from "react-select"

import {
  onStartLoading,
  onCreateReturnOrder,
  closeMessage,
  onFetchExistOrders,
  getDeliveryDetailsFromIsraelPost,
  clearOrderUpdate,
  onCreateOrdersFromFile,
  clearCreateOrdersFromFile,
} from "../../../actions"

import "./style.scss"

class CreateReturnOrderScreen extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      packageNumber: "",
      mobilePhone: "",
      firstName: "",
      lastName: "",
      email: "",
      showLoading: false,
      stationsList: [],
      stationNumber: "",
      currentStationName: "",
      bagsQuantity: "",
      externalOrderNumber2: "",
      stationsFiltered: [],
      isShowCreateOrdersFail: false,
      isShowCreateOrdersSuccess: false,
      errorText: "",
      validField: {
        stationNumber: {
          type: "stationNumber",
          isRequired: true,
          isChecked: false,
          compareMinValue: 1,
          compareMaxValue: 10,
          isValid: false,
        },
        packageNumber: {
          type: "packageNumber",
          isRequired: true,
          isChecked: false,
          compareMinValue: 1,
          compareMaxValue: 30,
          isValid: false,
        },
        mobilePhone: {
          type: "mobile",
          isRequired: true,
          isChecked: false,
          isValid: false,
        },
        firstName: {
          type: "name",
          isRequired: true,
          isChecked: false,
          compareMinValue: 2,
          compareMaxValue: 30,
          isValid: false,
        },
        lastName: {
          type: "name",
          isRequired: true,
          isChecked: false,
          compareMinValue: 2,
          compareMaxValue: 30,
          isValid: false,
        },
        email: {
          type: "email",
          isRequired: false,
          isChecked: false,
          compareMinValue: 5,
          compareMaxValue: 50,
          isValid: false,
        },
        bagsQuantity: {
          type: "bagsQuantity",
          isRequired: true,
          isChecked: false,
          compareMinValue: 1,
          compareMaxValue: 30,
          isValid: false,
        },
        externalOrderNumber2: {
          type: "externalOrderNumber2",
          isRequired: true,
          isChecked: false,
          compareMinValue: 1,
          compareMaxValue: 30,
          isValid: false,
        },
      },
    }
    this.state = this.INIT_STATE
  }
  componentDidMount() {
    const { authenticated, history, orderList } = this.props

    if (!authenticated) {
      history.push("/")
    }
    this.state = this.INIT_STATE

    if (orderList && orderList.length == 0) {
      this.props.onFetchExistOrders()
    }
  }

  componentDidUpdate(prevProps) {
    const { authenticated, history } = prevProps
    if (!authenticated) {
      history.push("/")
    }
    const { israelPostDelivery } = this.props
    if (
      israelPostDelivery &&
      prevProps.israelPostDelivery !== israelPostDelivery
    ) {
      this.populateUserFieldsByPackage()
    }
    if (this.props.createOrdersFromFileSuccess) {
      const failedOrders = this.checkForFailedOrders()
      this.setState({ failedOrdersList: failedOrders })
      this.generateResponseMsg(failedOrders)
    }
    if (this.props.createOrdersFromFileFail) {
      this.props.clearCreateOrdersFromFile()
      this.setState({ isShowCreateOrdersFail: true })
    }
  }

  componentWillUnmount() {
    this.state = this.INIT_STATE
  }

  setValue(inputName, e) {
    switch (inputName) {
      case "packageNumber":
        this.setState({ packageNumber: e.target.value })
        break
      case "mobilePhone":
        this.setState({ mobilePhone: e.target.value })
        break
      case "firstName":
        this.setState({ firstName: e.target.value })
        break
      case "lastName":
        this.setState({ lastName: e.target.value })
        break
      case "email":
        this.setState({ email: e.target.value })
        break
      case "bagsQuantity":
        this.setState({ bagsQuantity: e.target.value })
        break
      case "externalOrderNumber2":
        this.setState({ externalOrderNumber2: e })
        break
      default:
        break
    }
  }

  onInputFocus(inputName) {
    let { validField } = this.state
    let field = validField[inputName]
    if (!field) return

    field.isChecked = false
    field.isValid = false
    validField[inputName] = field

    this.setState({
      ...this.state,
      validField,
    })
  }

  validateInputValue(inputName, value) {
    let { validField } = this.state
    let field = validField[inputName]
    if (!field) return

    field.isChecked = true
    field.isValid = isValidField(
      value,
      field.type,
      field.isRequired,
      field.compareMinValue,
      field.compareMaxValue
    )
    validField[inputName] = field

    this.setState({
      ...this.state,
      validField,
    })
  }

  isValidForm(order) {
    const { validField } = this.state

    this.validateInputValue("packageNumber", order.packageNumber)
    this.validateInputValue("mobilePhone", order.mobilePhone)
    this.validateInputValue("firstName", order.firstName)
    this.validateInputValue("lastName", order.lastName)
    this.validateInputValue("email", order.email)

    const isValid =
      validField.packageNumber.isChecked &&
      validField.packageNumber.isValid &&
      validField.mobilePhone.isChecked &&
      validField.mobilePhone.isValid &&
      validField.firstName.isChecked &&
      validField.firstName.isValid &&
      validField.lastName.isChecked &&
      validField.lastName.isValid &&
      validField.email.isChecked &&
      validField.email.isValid //&&
    //validField.externalOrderNumber2.isChecked &&
    //validField.externalOrderNumber2.isValid
    return isValid
  }

  setOrderStatus() {
    if (this.props.userData && this.props.userData.role == "GeffenMedical") {
      return 19
    }
    return 3
  }

  onClickCreateReturnOrder() {
    const {
      packageNumber,
      mobilePhone,
      firstName,
      lastName,
      email,
      externalOrderNumber2,
    } = this.state
    if (
      !this.isValidForm({
        packageNumber,
        mobilePhone,
        firstName,
        lastName,
        email,
        externalOrderNumber2,
      })
    ) {
      return
    }
    //const orderStatus = this.setOrderStatus()
    //this.props.clearOrderUpdate()
    this.props.onCreateReturnOrder(
      packageNumber,
      mobilePhone,
      firstName,
      lastName,
      email,
      18,
      externalOrderNumber2.value
    )
  }

  onMessageCkickOk(e) {
    const { isError } = this.props
    if (isError) {
      this.props.closeMessage()
      return
    }

    this.props.closeMessage()
    this.setState({
      packageNumber: "",
      mobilePhone: "",
      firstName: "",
      lastName: "",
      email: "",
      externalOrderNumber2: "",
    })
  }

  findUserByMobilePhone(orderList, mobilePhone) {
    return orderList.find((order) => {
      if (order.mobilePhone === mobilePhone) {
        return order
      }
    })
  }

  populateUserFieldsByMobile(e) {
    const { orderList } = this.props
    const mobilePhone = e.target.value
    this.setValue("mobilePhone", e)

    if (mobilePhone.length == 10) {
      if (orderList && orderList.length > 0) {
        const order = this.findUserByMobilePhone(orderList, mobilePhone)
        if (order) {
          this.setState({
            firstName: order.firstName,
            lastName: order.lastName,
          })
        }
      }
    }
  }

  populateUserFieldsByPackage() {
    const { fName, lName, phone } = this.props.israelPostDelivery

    this.setState({
      firstName: fName,
      lastName: lName,
      mobilePhone: phone,
    })
  }

  onChangePackageNumberHandle(e) {
    const packageNumber = e.target.value
    this.setValue("packageNumber", e)
    if (packageNumber.length == 13 && this.props.userData.role != "Decathlon") {
      this.props.getDeliveryDetailsFromIsraelPost(packageNumber)
    }
  }

  handleFileChange(e) {
    const file = e.target.files[0]
    this.props.onCreateOrdersFromFile(file, 32, 18)
  }

  generateResponseMsg(failedOrders) {
    let msg = ""
    this.props.clearCreateOrdersFromFile()
    if (failedOrders.length == 0) {
      this.setState({ isShowCreateOrdersSuccess: true })
      return
    }
    if (
      failedOrders.length == this.props.createOrdersFromFileResponse.data.length
    ) {
      this.setState({ isShowCreateOrdersFail: true })
      return
    }
    if (failedOrders.length > 0) {
      msg = "ההזמנות הבאות נכשלו: "
      failedOrders.forEach((order) => {
        msg += order + ", "
      })
      msg = msg.substring(0, msg.length - 2)
      this.setState({ errorText: msg, isShowCreateOrdersSuccess: true })
      return
    }
  }

  clearCreateOrdersFromFile() {
    this.props.clearCreateOrdersFromFile()
    this.setState({
      isShowCreateOrdersFail: false,
      isShowCreateOrdersSuccess: false,
      errorText: "",
    })
  }

  checkForFailedOrders() {
    const { createOrdersFromFileResponse } = this.props
    let failedOrdersList = []
    if (createOrdersFromFileResponse?.data.length > 0) {
      createOrdersFromFileResponse?.data.forEach((order) => {
        if (order.status === false) {
          failedOrdersList.push(order.packageNumber)
        }
      })
    }
    return failedOrdersList
  }

  render() {
    const {
      validField,
      packageNumber,
      mobilePhone,
      firstName,
      lastName,
      email,
      isShowCreateOrdersSuccess,
      isShowCreateOrdersFail,
      errorText,
    } = this.state

    const showSubmitButton = true
    const { showMessage, isError, isLoading, userData } = this.props

    if (isLoading) {
      return (
        <div>
          <Title text="יצירת הזמנה להחזרה" />
          <Loader show={isLoading} />
        </div>
      )
    }

    return (
      <div className="create-return-order">
        <Title text="יצירת הזמנה להחזרה" />
        <InputField
          isFocused={true}
          show={true}
          value={packageNumber}
          placeholder="מספר חבילה"
          mandatory={true}
          showValidation={validField.packageNumber.isChecked}
          showValidationOk={validField.packageNumber.isValid}
          showValidationError={!validField.packageNumber.isValid}
          validationErrorMessage="מספר חבילה לא תקין"
          onFocus={(e) => this.onInputFocus("packageNumber")}
          onBlur={(e) =>
            this.validateInputValue("packageNumber", e.target.value)
          }
          onChange={(e) => this.onChangePackageNumberHandle(e)}
          maxLength={30}
        />
        <InputField
          show={true}
          value={mobilePhone}
          placeholder="מספר טלפון"
          mandatory={true}
          showValidation={validField.mobilePhone.isChecked}
          showValidationOk={validField.mobilePhone.isValid}
          showValidationError={!validField.mobilePhone.isValid}
          validationErrorMessage="מספר טלפון לא תקין"
          onFocus={(e) => this.onInputFocus("mobilePhone")}
          onBlur={(e) => this.validateInputValue("mobilePhone", e.target.value)}
          onChange={(e) => this.populateUserFieldsByMobile(e)}
          maxLength={10}
        />
        <InputField
          show={true}
          value={firstName}
          iconStyle=""
          placeholder="שם פרטי"
          mandatory={true}
          showValidation={validField.firstName.isChecked}
          showValidationOk={validField.firstName.isValid}
          showValidationError={!validField.firstName.isValid}
          validationErrorMessage="שם פרטי לא תקין"
          onFocus={(e) => this.onInputFocus("firstName")}
          onBlur={(e) => this.validateInputValue("firstName", e.target.value)}
          onChange={(e) => this.setValue("firstName", e)}
          maxLength={30}
        />
        <InputField
          show={true}
          value={lastName}
          iconStyle=""
          placeholder="שם משפחה"
          mandatory={true}
          showValidation={validField.lastName.isChecked}
          showValidationOk={validField.lastName.isValid}
          showValidationError={!validField.lastName.isValid}
          validationErrorMessage="שם משפחה לא תקין"
          onFocus={(e) => this.onInputFocus("lastName")}
          onBlur={(e) => this.validateInputValue("lastName", e.target.value)}
          onChange={(e) => this.setValue("lastName", e)}
          maxLength={30}
        />
        {userData && userData.accountType === "CDEK" && (
          <InputField
            show={true}
            value={email}
            iconStyle=""
            placeholder="אימייל "
            mandatory={false}
            showValidation={validField.email.isChecked}
            showValidationOk={validField.email.isValid}
            showValidationError={!validField.email.isValid}
            validationErrorMessage=" אימייל לא תקין"
            onFocus={(e) => this.onInputFocus("email")}
            onBlur={(e) => this.validateInputValue("email", e.target.value)}
            onChange={(e) => this.setValue("email", e)}
            maxLength={50}
          />
        )}

        <div className="buttons-wrapper">
          {showSubmitButton && (
            <Button onClick={(e) => this.onClickCreateReturnOrder()}>
              צור הזמנה להחזרה
            </Button>
          )}

          <Button onClick={(e) => this.props.history.goBack()}>חזור</Button>
          {this.props.userData?.accountType == "TandI" ? (
            <div>
              <label
                className="fileInputButton"
                onChange={(e) => this.handleFileChange(e)}
                htmlFor="ordersFile"
              >
                <input name="" type="file" id="ordersFile" hidden />
                טען קובץ
              </label>
              {isLoading ? "LoadingIndicator..." : null}
            </div>
          ) : null}
        </div>

        <Message
          show={showMessage}
          isError={isError}
          successText="ההזמנה הוספה בהצלחה"
          errorText="הוספת הזמנה נכשלה"
          onClick={(e) => this.onMessageCkickOk(e)}
        />
        <Message
          show={isShowCreateOrdersSuccess}
          isError={isError}
          successText={
            <div>
              הזמנות נוספו בהצלחה{" "}
              {errorText ? (
                <div className="failed-orders">{errorText}</div>
              ) : (
                ""
              )}
            </div>
          }
          errorText=""
          onClick={() => this.clearCreateOrdersFromFile()}
        />
        <Message
          show={isShowCreateOrdersFail}
          isError={true}
          successText=""
          errorText=" הוספת הזמנה נכשלה"
          onClick={() => this.clearCreateOrdersFromFile()}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ users, orders, station, israelPost }) => {
  const { authenticated, userData } = users
  const {
    showMessage,
    isError,
    isLoading,
    orderList,
    createOrdersFromFileSuccess,
    createOrdersFromFileFail,
    createOrdersFromFileResponse,
  } = orders
  const {
    stationsDecathlone,
    stationsItemSelectList,
    selectedDecathlonStation,
  } = station
  const { israelPostDelivery } = israelPost
  return {
    authenticated,
    showMessage,
    isError,
    isLoading,
    stationsDecathlone,
    userData,
    orderList,
    stationsItemSelectList,
    israelPostDelivery,
    selectedDecathlonStation,
    createOrdersFromFileSuccess,
    createOrdersFromFileFail,
    createOrdersFromFileResponse,
  }
}

export default connect(mapStateToProps, {
  onStartLoading,
  onCreateReturnOrder,
  closeMessage,
  onFetchExistOrders,
  getDeliveryDetailsFromIsraelPost,
  clearOrderUpdate,
  onCreateOrdersFromFile,
  clearCreateOrdersFromFile,
})(CreateReturnOrderScreen)
