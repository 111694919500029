import React from 'react';

import './style.css';

const Header = ({ titles, gravity }) => {
    if(!titles) return(<></>);
    return (
        <div className='list-header-wrapper'>
            {renderHeaderItems(titles, gravity)}
        </div>
    );
}
const renderHeaderItems = (items, gravity) => {
    
    return items.map((itm, index) => {

        const flex = gravity[`${index}`] || 1;

        if(itm === 'עמדות מחוברות למסלול'){
            return (
                <div style={{ flex }} key={index} className={'station-name'}>
                    {itm}
                </div>
            );
        }

        return (
            <div style={{ flex }} key={index}>
                {itm}
            </div>
        );
    });
}

export default Header;
