import {
  ON_START_LOADING,
  ON_FETCH_EXIST_ORDERS_SUCCESS,
  ON_FETCH_EXIST_ORDERS_FAIL,
  ON_CREATE_ORDER_SUCCESS,
  ON_CREATE_ORDER_FAIL,
  SET_ORDER_TO_UPDATE,
  ON_UPDATE_ORDER_SUCCESS,
  ON_UPDATE_ORDER_FAIL,
  ON_CLOSE_MESSAGE,
  RESET_ALL_REDUCERS,
  ON_GET_ORDER_DETAILS_REQUEST,
  ON_GET_ORDER_DETAILS_SUCCESS,
  CLEAR_ORDER_UPDATE,
  ON_UPDATE_ORDER_STATUS_SUCCESS,
  ON_UPDATE_ORDER_STATUS_FAIL,
  ON_CREATE_RETURN_ORDER_SUCCESS,
  ON_CREATE_RETURN_ORDER_FAIL,
  ON_LOCKER_TO_LOCKER_SUCCESS,
  ON_LOCKER_TO_LOCKER_FAIL,
  CLEAR_LOCKER_TO_LOCKER,
  ON_UPDATE_ORDER_STATUS_BY_ACCOUNT_SUCCESS,
  ON_UPDATE_ORDER_STATUS_BY_ACCOUNT_FAIL,
  ON_GET_EXTERNAL_NUMBER_BY_PACKAGE_SUCCESS,
  ON_GET_EXTERNAL_NUMBER_BY_PACKAGE_FAIL,
  CLEAR_GET_EXTERNAL_NUMBER,
  ON_CREATE_ORDER_FROM_FILE_LOADING,
  ON_CREATE_ORDER_FROM_FILE_SUCCESS,
  ON_CREATE_ORDER_FROM_FILE_FAIL,
  CLEAR_CREATE_ORDER_FROM_FILE,
} from "../actions/types"

const INIT_STATE = {
  orderList: null,
  selectedOrder: null,
  errTitle: null,
  errText: null,
  isLoading: false,
  isError: false,
  showMessage: false,
  response: null,
  orderDetails: null,
  orderStatusUpdateSuccess: false,
  orderStatusUpdateFail: false,
  orderStatusUpdateByAccountSuccess: false,
  orderStatusUpdateByAccountFail: false,
  externalOrderNumber: null,
  getExternalOrderNumberSuccess: false,
  getExternalOrderNumberFail: false,
  createOrdersFromFileSuccess: false,
  createOrdersFromFileFail: false,
  createOrdersFromFileResponse: null,
}

const OrderReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_START_LOADING:
      return {
        ...INIT_STATE,
        isLoading: true,
      }
    case CLEAR_ORDER_UPDATE:
      return {
        ...state,
        selectedOrder: null,
        errTitle: null,
        errText: null,
        isLoading: false,
        isError: false,
        showMessage: false,
        response: null,
        orderDetails: null,
        orderStatusUpdateSuccess: false,
        orderStatusUpdateFail: false,
        createLockerToLockerSuccess: false,
        createLockerToLockerFail: false,
        orderStatusUpdateByAccountSuccess: false,
        orderStatusUpdateByAccountFail: false,
        getExternalOrderNumberSuccess: true,
        getExternalOrderNumberFail: true,
      }
    case ON_FETCH_EXIST_ORDERS_SUCCESS:
      return {
        ...state,
        orderList: action.payload.data,
        isLoading: false,
        showMessage: false,
        isError: false,
      }
    case ON_CREATE_RETURN_ORDER_SUCCESS:
    case ON_CREATE_ORDER_SUCCESS:
      return {
        ...state,
        response: action.payload.data[0],
        isError: !action.payload.data[0].status,
        isLoading: false,
        showMessage: true,
      }
    case SET_ORDER_TO_UPDATE:
      return {
        ...state,
        selectedOrder: action.payload,
        showMessage: false,
        isError: false,
      }
    case ON_UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        response: action.payload.data[0],
        isError: !action.payload.data[0].status,
        showMessage: true,
        isLoading: false,
      }
    case ON_CREATE_RETURN_ORDER_FAIL:
    case ON_FETCH_EXIST_ORDERS_FAIL:
    case ON_CREATE_ORDER_FAIL:
    case ON_UPDATE_ORDER_FAIL:
      return {
        ...state,
        isError: true,
        errTitle: action.payload.errTitle,
        errText: action.payload.errText,
        showMessage: true,
        isLoading: false,
      }
    case ON_CLOSE_MESSAGE:
      return {
        ...state,
        showMessage: false,
      }
    case RESET_ALL_REDUCERS:
      return {
        ...INIT_STATE,
      }
    case ON_GET_ORDER_DETAILS_REQUEST:
      return {
        ...state,
        orderDetails: null,
      }
    case ON_GET_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        orderDetails: action.payload,
      }
    case ON_UPDATE_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        orderStatusUpdateSuccess: true,
        orderStatusUpdateFail: false,
      }
    case ON_UPDATE_ORDER_STATUS_FAIL:
      return {
        ...state,
        orderStatusUpdateSuccess: false,
        orderStatusUpdateFail: true,
      }
    case ON_LOCKER_TO_LOCKER_SUCCESS:
      return {
        ...state,
        createLockerToLockerSuccess: true,
        createLockerToLockerFail: false,
      }
    case ON_LOCKER_TO_LOCKER_FAIL:
      return {
        ...state,
        createLockerToLockerSuccess: false,
        createLockerToLockerFail: true,
      }
    case CLEAR_LOCKER_TO_LOCKER:
      return {
        ...state,
        createLockerToLockerSuccess: false,
        createLockerToLockerFail: false,
      }
    case ON_UPDATE_ORDER_STATUS_BY_ACCOUNT_SUCCESS:
      return {
        ...state,
        orderStatusUpdateByAccountSuccess: true,
        orderStatusUpdateByAccountFail: false,
      }
    case ON_UPDATE_ORDER_STATUS_BY_ACCOUNT_FAIL:
      return {
        ...state,
        orderStatusUpdateByAccountSuccess: false,
        orderStatusUpdateByAccountFail: true,
      }
    case ON_GET_EXTERNAL_NUMBER_BY_PACKAGE_SUCCESS:
      return {
        ...state,
        externalOrderNumber: action.payload,
        getExternalOrderNumberSuccess: true,
        getExternalOrderNumberFail: false,
      }
    case ON_GET_EXTERNAL_NUMBER_BY_PACKAGE_FAIL:
      return {
        ...state,
        externalOrderNumber: null,
        getExternalOrderNumberSuccess: false,
        getExternalOrderNumberFail: true,
      }
    case CLEAR_GET_EXTERNAL_NUMBER:
      return {
        ...state,
        externalOrderNumber: null,
        getExternalOrderNumberFail: false,
        getExternalOrderNumberSuccess: false,
      }
    case ON_CREATE_ORDER_FROM_FILE_LOADING:
      return {
        ...state,
        isLoading: true,
        createOrdersFromFileSuccess: false,
        createOrdersFromFileFail: false,
        isError: false,
      }
    case ON_CREATE_ORDER_FROM_FILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        createOrdersFromFileResponse: action.payload,
        createOrdersFromFileSuccess: true,
        createOrdersFromFileFail: false,
        isError: false,
      }
    case ON_CREATE_ORDER_FROM_FILE_FAIL:
      return {
        ...state,
        isLoading: false,
        createOrdersFromFileResponse: action.payload,
        createOrdersFromFileSuccess: false,
        createOrdersFromFileFail: true,
        isError: true,
      }
    case CLEAR_CREATE_ORDER_FROM_FILE:
      return {
        ...state,
        isLoading: false,
        createOrdersFromFileResponse: null,
        createOrdersFromFileSuccess: false,
        createOrdersFromFileFail: false,
        isError: false,
      }
    default:
      return state
  }
}

export default OrderReducer
