import React from "react"
import "./style.scss"

const ButtonRectangle = ({ children, extraClass, show = true, ...props }) => {
  if (!show) {
    return <></>
  }
  return (
    <div className={`rectangle-button ${extraClass}`} {...props}>
      {children}
    </div>
  )
}

export { ButtonRectangle }
