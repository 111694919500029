import React from "react"
import { ButtonRectangle } from "../../../../common/ButtonRectangle"
import "./style.css"

const StationItem = ({ item, itemMap, gravity, isLast, onStatusBtnClick }) => {
  const itemClassName = `item-wrapper ${isLast ? "item-last" : ""}`

  return (
    <div className={itemClassName}>
      {itemMap.map((itmMap, index) => {
        const flex = gravity[`${index}`] || 1

        if (itmMap === "isDecathlon") {
          const itemClassName = !item[itmMap] ? "fa fa-check" : ""
          return (
            <div
              className={"clickable " + itemClassName}
              aria-hidden="true"
              style={{ flex }}
              key={index}
            ></div>
          )
        }

        if (itmMap === "edit") {
          return (
            <div style={{ flex }} key={index}>
              <ButtonRectangle
                extraClass="btn-status"
                onClick={(e) => onStatusBtnClick(item.stationId)}
              >
                סטטוס
              </ButtonRectangle>
            </div>
          )
        }

        let itemContent = item[itmMap]
        if (itmMap === "brokenLockersNum") {
          if (!itemContent || itemContent == 0) {
            return <div style={{ flex }} key={index}></div>
          }
          return (
            <div style={{ flex }} key={index}>
              <div className={"circle broken"}>{itemContent}</div>
            </div>
          )
        }
        if (itmMap === "emptyLockersNum") {
          if (!itemContent || itemContent == 0) {
            return <div style={{ flex }} key={index}></div>
          }
          return (
            <div style={{ flex }} key={index}>
              <div className={"circle empty"}>{itemContent}</div>
            </div>
          )
        }
        if (itmMap === "lockersNumber") {
          return (
            <div style={{ flex }} key={index}>
              <div className={"circle lockers"}>{itemContent}</div>
            </div>
          )
        }

        if (itmMap === "isAlive") {
          if (itemContent) {
            return (
              <div style={{ flex }} key={index} className="station-alive">
                <i className="fa fa-check-circle" aria-hidden="true"></i>
              </div>
            )
          }
          return (
            <div style={{ flex }} key={index} className="station-down">
              <i className="fa fa-times-circle" aria-hidden="true"></i>
            </div>
          )
        }

        return (
          <div style={{ flex }} key={index}>
            {itemContent}
          </div>
        )
      })}
    </div>
  )
}

export default StationItem
