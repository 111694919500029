import React, { Component } from 'react';
import './style.scss';



class MenuItem extends Component {


    
    render(){
        const {show} = this.props;

        if(!show){
            return(
                <></>
            );
        }
        const {text, onClick, ...props } = this.props;
        return(
            <div className="menu-item" onClick={e => onClick(e)}>
                {text}
            </div>
        );
    }
}

export default MenuItem;