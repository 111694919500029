import React from 'react';

import './style.css';

const SubmitButton = ({ className, title }) => {

  return (
    <input className={className} type="submit" value={title} />
  );
}

export { SubmitButton };