import React from "react";

import "./style.css";

/*const LabeledTextInput = ({ id, className, labelText, ...props }) => {
    return (
        <div id={id} className={className}>
            <label>{labelText}</label>
            <input type="text" {...props} />
        </div>
    );
}*/

const LabeledTextInput = ({
  labelText,
  inputSize,
  type = "text",
  disabled = false,
  ...props
}) => {
  return (
    <div className="ss-group">
      <label className="ss-label">{labelText}</label>
      <input
        className="ss-input"
        type={type}
        size={inputSize}
        disabled={disabled}
        {...props}
      />
    </div>
  );
};

export { LabeledTextInput };
