import React from "react"
import { ButtonRectangle } from "../ButtonRectangle"
import Modal from "../Modal"
import "./style.css"

const ExternalNumbersModal = ({ show, title, numbers, onOkClick }) => {
  if (!show) {
    return <noscript />
  }
  console.log(numbers)

  return (
    <Modal extraClass="noti-modal-wrap">
      <div className="noti-modal-container">
        <div className="external-modal-title">{title}</div>
        <div className="noti-modal-content-wrapper">
          <ul>
            {numbers.map((num) => (
              <li>{num}</li>
            ))}
          </ul>
        </div>
        <div className="btn-holder">
          <ButtonRectangle
            extraClass="noti-modal-button"
            onClick={(e) => onOkClick()}
          >
            אישור
          </ButtonRectangle>
        </div>
      </div>
    </Modal>
  )
}

export default ExternalNumbersModal
