import axios from "axios"
import api from "../api/BaseApi"
import { getStationsObject } from "../utils/"
import {
  ON_UPDATE_MESSAGE_SUCCESS,
  ON_UPDATE_MESSAGE_FAIL,
  GET_STATIONS,
  ON_CLOSE_MESSAGE,
  ON_GET_STATIONS_SUCCESS,
  ON_STATIONS_SET_PAGE,
  ON_SELECT_STATION_ID,
  ON_STATION_SHOW_LOADING,
  CLEAR_LOCAL_STATIONS_FILTER_SEARCH,
  FETCH_ALL_STATIONS_DATA,
  ON_STATIONS_FILTER_BY_STATION,
  ON_STATIONS_FILTER_BY_TEXT,
  ON_SET_SELECTED_STATION,
} from "../actions/types"

export const onUpdateMessage = (stationAddress, sendSMS, stationId) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    const body = { isSendSms: sendSMS, branchDisplayAddress: stationAddress }

    axios
      .put(api.updateMessage(stationId), body, options)
      .then(() => {
        dispatch({
          type: ON_UPDATE_MESSAGE_SUCCESS,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_UPDATE_MESSAGE_FAIL,
        })
        console.warn(err)
      })
  }
}

export const fetchAllStations = () => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    axios.get(api.getStations, options).then((res) => {
      dispatch({
        type: GET_STATIONS,
        payload: res.data,
      })
    })
  }
}

export const closeStationMessage = () => {
  return (dispatch) => {
    dispatch({
      type: ON_CLOSE_MESSAGE,
    })
  }
}

export const fetchStations = () => {
  return (dispatch) => {
    axios
      .get(api.getStationsByRole)
      .then((res) => {
        dispatch({
          type: ON_GET_STATIONS_SUCCESS,
          payload: getStationsObject(res.data),
        })
      })
      .catch((err) => {})
  }
}

export const localSearchStationsSetPage = (page) => {
  return {
    type: ON_STATIONS_SET_PAGE,
    payload: page,
  }
}

export const selectStation = (stationId) => {
  return {
    type: ON_SELECT_STATION_ID,
    payload: stationId,
  }
}

export const showStationLoading = () => {
  return {
    type: ON_STATION_SHOW_LOADING,
  }
}

export const clearLocalSearchStations = () => {
  return (dispach) => {
    dispach({
      type: CLEAR_LOCAL_STATIONS_FILTER_SEARCH,
    })
  }
}

export const fetchAllStationData = (stations) => {
  return {
    type: FETCH_ALL_STATIONS_DATA,
    payload: stations,
  }
}

export const localSearchStationsByStations = (choosedStationsItems) => {
  return {
    type: ON_STATIONS_FILTER_BY_STATION,
    payload: choosedStationsItems,
  }
}

export const localSearchStationsByText = (searchText) => {
  return {
    type: ON_STATIONS_FILTER_BY_TEXT,
    payload: searchText ? searchText : searchText,
  }
}

export const setSelectedStation = (stationNumber) => {
  return {
    type: ON_SET_SELECTED_STATION,
    payload: stationNumber,
  }
}
