import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import MenuItem from "./MenuItem"
import OpenMenuButton from "./OpenMenuButton"
import "./style.scss"

class Menu extends Component {
  constructor(props) {
    super(props)
    this.INIT_STATE = {
      isActive: false,
    }

    this.state = this.INIT_STATE
  }

  setActiveMenu(value) {
    this.setState({ isActive: value })
  }

  componentDidMount() {
    document.addEventListener("click", (event) => {
      const menu = document.getElementById("menu")
      let targetElement = event.target // clicked element

      do {
        if (targetElement == menu) {
          // This is a click inside. Do nothing, just return.
          return
        }
        // Go up the DOM
        targetElement = targetElement.parentNode
      } while (targetElement)
      // This is a click outside.
      this.setActiveMenu(false)
    })
  }

  isShowMenu() {
    const { history } = this.props
    if (
      history.location.pathname == "/" ||
      history.location.pathname == "/DecathlonStationSelectScreen"
    ) {
      return false
    }
    return true
  }

  render() {
    const {
      text,
      history,
      isAuthenticated,
      accountType,
      isAllowCreateOrder,
      setFullMenu,
      ...props
    } = this.props

    return this.isShowMenu() ? (
      <div className="menu-wrapper" id="menu">
        <OpenMenuButton
          isActive={this.state.isActive}
          onClickMe={(e) => this.setActiveMenu(e)}
        />
        <div className="menu-items-wrapper">
          <MenuItem
            show={this.state.isActive}
            text="הזמנות"
            onClick={(e) => {
              history.push("/OrdersListScreen")
              this.setActiveMenu(false)
            }}
          />

          {accountType == "Done" ? (
            <MenuItem
              show={this.state.isActive && isAllowCreateOrder}
              text="יצירת משלוח"
              onClick={(e) => {
                history.push("/CreateLockerToLockerScreen")
                this.setActiveMenu(false)
              }}
            />
          ) : accountType != "CDEK" ? (
            <MenuItem
              show={this.state.isActive && isAllowCreateOrder}
              text="יצירת הזמנה"
              onClick={(e) => {
                history.push("/CreateNewOrderScreen")
                this.setActiveMenu(false)
              }}
            />
          ) : null}

          {accountType !== "Done" && (
            <MenuItem
              show={this.state.isActive && isAllowCreateOrder}
              text="יצירת הזמנה להחזרה"
              onClick={(e) => {
                history.push("/CreateReturnOrderScreen")
                this.setActiveMenu(false)
              }}
            />
          )}
          <MenuItem
            show={this.state.isActive}
            text="סריקה"
            onClick={(e) => {
              history.push("/ScanOrderScreen")
              this.setActiveMenu(false)
            }}
          />
          {/* {role == "Decathlon" ||
          role == "YadMordechai" ||
          role == "SdeMoshe" ||
          role == "Amirim" ||
          role == "GeffenMedical" ? (
            <MenuItem
              show={this.state.isActive}
              text="יצירת הזמנה"
              onClick={(e) => {
                history.push("/CreateNewOrderScreen")
                this.setActiveMenu(false)
              }}
            />
          ) : null} */}

          {/* {role == "Decathlon" || role == "Manager" ? (
            <MenuItem
              show={this.state.isActive}
              text="עדכון הודעה"
              onClick={(e) => {
                history.push("/UpdateMessageScreen")
                this.setActiveMenu(false)
              }}
            />
          ) : null} */}

          {/* <MenuItem
            show={this.state.isActive}
            text="עמדות"
            onClick={(e) => {
              history.push("/StationsScreen")
              this.setActiveMenu(false)
            }}
          /> */}
          {/* <MenuItem
            show={this.state.isActive}
            text="משתמשים"
            onClick={(e) => {
              history.push("/UsersListScreen")
              this.setActiveMenu(false)
            }}
          /> */}

          {/* {role != "Decathlon" ? (
            <MenuItem
              show={this.state.isActive}
              text="דוח חיובי חבילות"
              onClick={(e) => {
                history.push("/PackageDebitReport")
                this.setActiveMenu(false)
              }}
            />
          ) : null} */}

          <MenuItem
            show={this.state.isActive}
            text="התנתק"
            onClick={(e) => {
              history.push("/")
              this.setActiveMenu(false)
            }}
          />
        </div>
      </div>
    ) : (
      <></>
    )
  }
}

export default withRouter(Menu)
