import React from "react"
import { ButtonRectangle } from "../ButtonRectangle"
import Modal from "../Modal"
import PDFReader from "../PDF/PDFReader" ///"..PDFReader/PDF/PDFReader"
import "./style.css"

const PDFReaderModal = ({ show, title, file, onOkClick }) => {
  if (!show) {
    return <noscript />
  }

  return (
    <Modal extraClass="noti-modal-wrap">
      <div className="noti-modal-container">
        <div className="external-modal-title">{title}</div>
        <div className="noti-modal-content-wrapper">
          <PDFReader file={file} />
        </div>
        <div className="btn-holder">
          <ButtonRectangle
            extraClass="noti-modal-button"
            onClick={(e) => onOkClick()}
          >
            סגור
          </ButtonRectangle>
        </div>
      </div>
    </Modal>
  )
}

export default PDFReaderModal
