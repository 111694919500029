import React, { useState, useEffect } from "react"
import { ButtonRectangle } from "../ButtonRectangle"

import "./style.css"

const Pagination = ({ fullList, onSubListReady, totalPerPage, pageNum }) => {
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    if (fullList) {
      onPageBtnClick(
        pageNum,
        setCurrentPage,
        onSubListReady,
        fullList,
        totalPerPage
      )
    }
  }, [fullList ? fullList.length : 0])

  if (!fullList) {
    return <div />
  }

  // const pagesArr = [...new Array(numOfPages).keys()].map((arrItm, index) => index + 1);
  const numOfPages = Math.ceil(fullList.length / totalPerPage)
  const pagesArr = constructPagesArr(numOfPages, currentPage)

  return (
    <div className="pagin-wrapper">
      {pagesArr.map((arrItm, index) => {
        if (!Number.isInteger(arrItm)) {
          return renderNonNumberBtn(
            arrItm,
            index,
            pagesArr,
            currentPage,
            setCurrentPage,
            onSubListReady,
            fullList,
            totalPerPage,
            numOfPages
          )
        }

        return (
          <ButtonRectangle
            extraClass={`pagin-btn ${
              arrItm === currentPage ? "pagin-selected" : ""
            }`}
            onClick={(e) =>
              onPageBtnClick(
                arrItm,
                setCurrentPage,
                onSubListReady,
                fullList,
                totalPerPage
              )
            }
            key={index}
          >
            {arrItm}
          </ButtonRectangle>
        )
      })}
    </div>
  )
}

const renderNonNumberBtn = (
  arrItm,
  index,
  pagesArr,
  currentPage,
  setCurrentPage,
  onSubListReady,
  fullList,
  totalPerPage,
  numOfPages
) => {
  let newDestPage

  if (arrItm === "<" || arrItm === ">") {
    newDestPage = arrItm === "<" ? currentPage - 1 : currentPage + 1
  }

  if (arrItm === "...") {
    newDestPage =
      Math.floor((pagesArr[index + 1] - pagesArr[index - 1]) / 2) +
      pagesArr[index - 1]
  }

  newDestPage = Math.min(newDestPage, numOfPages)
  newDestPage = Math.max(newDestPage, 1)

  return (
    <ButtonRectangle
      extraClass="pagin-btn"
      onClick={(e) =>
        onPageBtnClick(
          newDestPage,
          setCurrentPage,
          onSubListReady,
          fullList,
          totalPerPage
        )
      }
      key={index}
    >
      {arrItm}
    </ButtonRectangle>
  )
}

const constructPagesArr = (numOfPages, currentPage) => {
  let result = []

  if (numOfPages < 1) {
    return result
  }

  if (numOfPages <= 10) {
    return [
      "<",
      ...[...new Array(numOfPages).keys()].map((arrItm, index) => index + 1),
      ">",
    ]
  }

  if (currentPage < 3) {
    result = [2, 3, "..."]
  } else if (numOfPages - currentPage <= 3) {
    result = ["...", numOfPages - 3, numOfPages - 2, numOfPages - 1]
  } else {
    result = ["...", currentPage - 1, currentPage, currentPage + 1, "..."]
  }

  result = ["<", 1, ...result, numOfPages, ">"]

  return result
}

const onPageBtnClick = (
  pageNum,
  setCurrentPage,
  onSubListReady,
  fullList,
  totalPerPage
) => {
  setCurrentPage(pageNum)

  if (!fullList) {
    return
  }

  const startingPageEntryIndex = totalPerPage * (pageNum - 1)
  const endingPageEntryIndex = startingPageEntryIndex + totalPerPage

  const resultSubList = fullList.slice(
    startingPageEntryIndex,
    endingPageEntryIndex
  )
  onSubListReady(resultSubList, pageNum)
}

export { Pagination }
