import React from "react"
import { Input } from "reactstrap"

export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
  resetFilterValue,
}) => {
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach((row) => {
      options.add(row.values[id])
    })
    return ["הצג הכל", ...options.values()]
  }, [id, preFilteredRows])

  return (
    <Input
      id="custom-select"
      type="select"
      value={filterValue || "הצג הכל"}
      onChange={(e) => {
        const value = e.target.value === "הצג הכל" ? undefined : e.target.value
        setFilter(value)
      }}
    >
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </Input>
  )
}
