import axios from "axios"
import api from "../api/BaseApi"

import {
  saveResultInBgServiceKeyValue,
  readResultFromBgServiceKeyValue,
} from "../utils/LocalServiceOperations"
import {
  FETCH_ALL_LOCKERS_STATUSES_SUCCESS,
  FETCH_ALL_LOCKERS_LAYOUT_SUCCESS,
  CLEAR_LOCKERS_STATUSES,
} from "./types"

import { axiosGetRetry } from "../utils/ReqsRetry"
const ENABLE_BG_SYNC = false
export const fetchAllLockersStatuses = (stationId) => {
  return (dispatch) => {
    // axios.get(api.getLockersStatusesByStationId(stationId))
    axiosGetRetry(api.getLockersStatusesByStationId(stationId))
      .then((res) => {
        dispatch({
          type: FETCH_ALL_LOCKERS_STATUSES_SUCCESS,
          payload: res.data,
        })

        if (ENABLE_BG_SYNC) {
          saveResultInBgServiceKeyValue(
            api.getLockersStatusesByStationId(stationId),
            res.data
          )
        }
      })
      .catch((err) => {
        if (ENABLE_BG_SYNC) {
          readResultFromBgServiceKeyValue(
            api.getLockersStatusesByStationId(stationId)
          )
            .then((savedVal) => {
              dispatch({
                type: FETCH_ALL_LOCKERS_STATUSES_SUCCESS,
                payload: savedVal,
              })
            })
            .catch((err) => console.log(err)) //??????????????????TODO
        }
      })
  }
}

export const fetchAllLockersLayout = (stationId) => {
  return (dispatch) => {
    axiosGetRetry(api.getLockersLayoutByStationId(stationId))
      .then((res) => {
        const { layout } = res.data

        dispatch({
          type: FETCH_ALL_LOCKERS_LAYOUT_SUCCESS,
          payload: JSON.parse(layout),
        })

        if (ENABLE_BG_SYNC) {
          saveResultInBgServiceKeyValue(
            api.getLockersLayoutByStationId(stationId),
            res.data
          )
        }
      })
      .catch((err) => {
        if (ENABLE_BG_SYNC) {
          readResultFromBgServiceKeyValue(
            api.getLockersLayoutByStationId(stationId)
          )
            .then((savedVal) => {
              const { layout } = savedVal

              dispatch({
                type: FETCH_ALL_LOCKERS_LAYOUT_SUCCESS,
                payload: JSON.parse(layout),
              })
            })
            .catch((err) => console.log(err))
        }
        // USE WHEN OFFLINE..!
        // dispatch({
        //     type: FETCH_ALL_LOCKERS_LAYOUT_SUCCESS,
        //     payload: arr
        // });
      })
  }
}

export const clearLockersStatuses = () => {
  return (dispach) => {
    dispach({
      type: CLEAR_LOCKERS_STATUSES,
    })
  }
}
