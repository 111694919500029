/*
const ORDER_TYPE_ENUM =
[
    'לא ידוע',                  //'Not available',
    'כביסה',                    //'Laundry',
    'דואר',                     //'Mail'
    'מוצר',                     // 'Product' = 3
    'סוג 1',              // 'OrderType1' = 4
    'סוג 2',              // 'OrderType2' = 5
    'סוג 3',              // 'OrderType3' = 6
    'סוג 4',              // 'OrderType4' = 7
    'סוג 5',              // 'OrderType5' = 8
    'סוג 6',              // 'OrderType6' = 9
    'סוג 7',              // 'OrderType7' = 10
    'סוג 8',              // 'OrderType8' = 11
    'סוג 9',              // 'OrderType9' = 12
    'סוג 10',             // 'OrderType10' = 13
    'סוג 11',             // 'OrderType11' = 14
    'סוג 12',             // 'OrderType12' = 15
    'סוג 13',             // 'OrderType13' = 16
    'סוג 14',             // 'OrderType14' = 17
    'סוג 15',             // 'OrderType15' = 18
    'סוג 16',             // 'OrderType16' = 19
    'סוג 17',             // 'OrderType17' = 20
    'סוג 18',             // 'OrderType18' = 21
    'סוג 19',             // 'OrderType19' = 22
    'סוג 20',             // 'OrderType20' = 23
]
*/

export const getOrderTypeTextFromOrderType = (orderTypeArr, orderType) => {
  if (
    !orderTypeArr ||
    orderTypeArr === undefined ||
    isNaN(orderType) ||
    orderTypeArr.length <= orderType
  ) {
    return orderType;
  }
  return orderTypeArr[orderType];
};
