import React from "react";

import "../../SelectField/style.scss";

const SelectStationField = ({
  show = true,
  labelText,
  options = [],
  value,
  onChange,
}) => {
  return (
    <div className="select-wrapper">
      <label>{labelText}</label>
      <select value={value} onChange={onChange}>
        {options.map((station) => {
          return (
            <option value={station?.stationNumber} key={station?.stationNumber}>
              {station?.branchDisplayName}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default SelectStationField;
