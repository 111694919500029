import React, { useEffect, useMemo, useState } from "react"
import { connect } from "react-redux"
import TableContainer from "../../../common/TableContainer"
import { SelectColumnFilter } from "../../../../filters/SelectColumnFilter"
import { Container } from "reactstrap"
import { getFormatedDate } from "../../../../utils/FormatedDate"
import { setEmtyIfNull } from "../../../../utils/getIfEmpty"
import history_icon from "../../../../assets/svg/history_icon.svg"
import list_icon from "../../../../assets/svg/list_icon.svg"
import {
  addToPrintList,
  removeFromPrintList,
} from "../../../../actions/BarcodeActions"

import "./style.scss"

const OrdersList = (props) => {
  let [data] = useState([])
  const [filterValue, setFilterValue] = useState("")
  const [hiddenColumns, setHiddenColumns] = useState(["orderStatusId"])

  const {
    id,
    orderList,
    onHistoryClick,
    onListIconClick,
    onPDFClick,
    ordersStatusesArr,
    onCancelOrderClick,
    refreshTable,
    extendPackageClick,
  } = props

  const formateOrder = (order) => {
    const {
      id,
      orderNumber,
      packageNumber,
      externalOrderNumber,
      externalOrderNumber2,
      mobilePhone,
      firstName,
      lastName,
      orderStatus,
      orderDate,
      branchDisplayName,
      createdByName,
      inLockerExtentionDate,
      email,
    } = order

    return {
      id,
      branchDisplayName,
      orderNumber,
      packageNumber,
      externalOrderNumber,
      externalOrderNumber2,
      mobilePhone: setEmtyIfNull(mobilePhone),
      firstName: setEmtyIfNull(firstName),
      lastName: setEmtyIfNull(lastName),
      orderStatus: ordersStatusesArr[orderStatus],
      orderStatusId: orderStatus,
      orderDate: getFormatedDate(orderDate),
      createdByName,
      inLockerExtentionDate: getFormatedDate(inLockerExtentionDate),
      email: setEmtyIfNull(email),
    }
  }

  const ordersByStation = () => {
    const { orderList, selectedStation } = props
    if (orderList) {
      let filteredOrders
      if (selectedStation) {
        filteredOrders = orderList.filter((order) => {
          return order.stationNumber == selectedStation
        })
      } else {
        filteredOrders = orderList
      }

      return filteredOrders
        ? filteredOrders.map((order) => formateOrder(order))
        : []
    } else {
      return []
    }
  }

  const handleCheckboxChange = (event, packageNumber, orderNumber) => {
    const isChecked = event.target.checked
    if (isChecked) {
      props.addToPrintList(packageNumber, orderNumber)
    } else {
      props.removeFromPrintList(packageNumber, orderNumber)
    }
  }

  data = useMemo(() => ordersByStation(), [orderList])

  const columns = useMemo(() => {
    const result = [
      {
        Header: "תאריך",
        accessor: "orderDate",
      },
      {
        Header: "מספר הזמנה",
        accessor: "orderNumber",
      },
      {
        Header: "טלפון",
        accessor: "mobilePhone",
      },
      {
        Header: "שם פרטי",
        accessor: "firstName",
      },
      {
        Header: "שם משפחה",
        accessor: "lastName",
      },
      {
        Header: "סטטוס",
        accessor: "orderStatus",
        Filter: SelectColumnFilter,
        filter: filterValue,
      },
      {
        Header: "שם העמדה",
        accessor: "branchDisplayName",
      },
      {
        Header: "נוצר על ידי",
        accessor: "createdByName",
      },
      {
        Header: "תאריך הארכה",
        accessor: "inLockerExtentionDate",
      },
      {
        Header: "orderStatusId",
        accessor: "orderStatusId",
      },
    ]
    if (props.userData.accountType == "Done") {
      result.splice(2, 0, {
        Header: " מספר חבילה ",
        accessor: "externalOrderNumber",
      })
    }

    if (props.userData.accountType != "Done") {
      result.splice(2, 0, {
        Header: "מספר חבילה",
        accessor: "packageNumber",
      })
    }

    if (props.userData.accountType == "NegroCoffee") {
      result.splice(16, 0, {
        Header: "אימייל",
        accessor: "email",
      })
    }

    result.splice(0, 0, {
      Header: () => null,
      id: "key",
      Cell: ({ row }) => (
        <img
          className="order-edit"
          alt="edit_button"
          src={history_icon}
          onClick={() => onHistoryClick(row)}
        />
      ),
    })
    result.splice(0, 0, {
      Header: () => null,
      id: "list",
      Cell: ({ row }) =>
        row.original.externalOrderNumber !== "" ? (
          <img
            className="order-edit"
            alt="edit_button"
            src={list_icon}
            onClick={() => onListIconClick(row)}
          />
        ) : null,
    })

    result.splice(0, 0, {
      Header: () => null,
      id: "check_print",

      Cell: ({ row }) => {
        const packageNumber = row.original.packageNumber
        const orderNumber = row.original.orderNumber
        return packageNumber !== "" && row.original.orderStatus != "מבוטל" ? (
          <input
            type="checkbox"
            onChange={(event) =>
              handleCheckboxChange(event, packageNumber, orderNumber)
            }
            checked={props.orderNumbersForPrint?.includes(orderNumber)}
            aria-label="print_checkbox"
          />
        ) : null
      },
    })

    result.splice(0, 0, {
      Header: () => null,
      id: "pdf",
      Cell: ({ row }) =>
        row.original.packageNumber !== "" &&
        row.original.orderStatus != "מבוטל" ? (
          <i
            className="fas fa-print order-edit"
            aria-hidden="true"
            alt="pdf_button"
            onClick={() => onPDFClick(row)}
          ></i>
        ) : null,
    })

    result.splice(0, 0, {
      Header: () => null,
      id: "cancel_order",
      Cell: ({ row }) =>
        row.original.orderStatusId === 19 ||
        row.original.orderStatusId === 18 ? (
          <i
            className="fas fa-trash order-edit"
            aria-hidden="true"
            alt="cancel_order_button"
            onClick={() => onCancelOrderClick(row.original)}
          ></i>
        ) : null,
    })
    result.splice(4, 0, {
      Header: () => null,
      id: "delay",
      Cell: ({ row }) =>
        row.original.orderStatusId === 3 ||
        row.original.orderStatusId === 4 ||
        row.original.orderStatusId === 13 ? (
          <i
            className="fa fa-calendar"
            aria-hidden="true"
            alt="delay_button"
            onClick={() => extendPackageClick(row.original)}
          ></i>
        ) : null,
    })

    return result
  }, [filterValue, props.packageNumbersForPrint])

  const clearFilterAndRefreshTable = () => {
    setFilterValue("")
    refreshTable()
  }

  return (
    <div className="order-list-wrapper">
      <Container className="order-list">
        <TableContainer
          columns={columns}
          hiddenColumns={hiddenColumns}
          data={data}
          refreshTable={() => clearFilterAndRefreshTable()}
          filterValue={filterValue}
          printAllSelectedBarcodes={() => props.printAllSelectedBarcodes()}
          resetFilterValue={() => setFilterValue("")}
          isLoading={props.isLoading}
        />
      </Container>
    </div>
  )
}

const mapStateToProps = ({
  orders,
  orderStatuses,
  users,
  station,
  barcode,
}) => {
  const { orderList } = orders
  const { userData } = users
  const { selectedStation } = station
  const { ordersStatusesArr } = orderStatuses
  const { packageNumbersForPrint, orderNumbersForPrint, isLoading } = barcode
  return {
    orderList,
    ordersStatusesArr,
    userData,
    selectedStation,
    packageNumbersForPrint,
    orderNumbersForPrint,
    isLoading,
  }
}

export default connect(mapStateToProps, {
  addToPrintList,
  removeFromPrintList,
})(OrdersList)
