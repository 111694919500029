import React from 'react';

import './style.css';

const SmallHeader = ({ title = 'כניסה למערכת' }) => {
    const laundryLogoSrc = require('../../../assets/images/logo_laundry.png');

    return (
        <div className='sml-header-wrapper'>
            <div className='sml-header-title'>
                {title}
            </div>
            <img src={laundryLogoSrc} alt='' className='sml-header-img-logo' />
        </div>
    );
}

export { SmallHeader };