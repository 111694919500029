import {
  ON_GET_BARCODE_SUCCESS,
  ON_GET_BARCODE_FAIL,
  ON_CLEAR_BARCODE,
  ON_LOAD_BARCODE,
  ON_ADD_TO_PRINT_LIST,
  ON_REMOVE_FROM_PRINT_LIST,
  ON_GET_BARCODE_LIST_SUCCESS,
  ON_GET_BARCODE_LIST_FAIL,
  ON_LOAD_BARCODE_LIST,
  CLEAR_BARCODE_LIST,
  CLEAR_ORDER_IDS_FOR_PRINT,
} from "../actions/types"

const INIT_STATE = {
  file: null,
  isLoading: false,
  isFail: false,
  isSuccess: false,
  packageNumbersForPrint: [],
  orderNumbersForPrint: [],
  barcodePDFList: [],
}

const BarcodeReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_LOAD_BARCODE:
      return {
        ...state,
        file: null,
        isSuccess: false,
        isFail: false,
        isLoading: true,
      }

    case ON_GET_BARCODE_SUCCESS:
      return {
        ...state,
        file: action.payload,
        isSuccess: true,
        isFail: false,
        isLoading: false,
      }
    case ON_GET_BARCODE_FAIL:
      return {
        ...state,
        israelPostDelivery: null,
        israelPostDeliveryFail: true,
      }
    case ON_CLEAR_BARCODE:
      return {
        ...state,
        file: null,
        isSuccess: false,
        isFail: false,
        isLoading: false,
      }
    case ON_ADD_TO_PRINT_LIST:
      return {
        ...state,
        packageNumbersForPrint: [
          ...state.packageNumbersForPrint,
          action.payload.packageNumber,
        ],
        orderNumbersForPrint: [
          ...state.orderNumbersForPrint,
          action.payload.orderNumber,
        ],
      }
    case ON_REMOVE_FROM_PRINT_LIST:
      return {
        ...state,
        packageNumbersForPrint: state.packageNumbersForPrint.filter(
          (item) => item !== action.payload.packageNumber
        ),
        orderNumbersForPrint: state.orderNumbersForPrint.filter(
          (item) => item !== action.payload.orderNumber
        ),
      }
    case CLEAR_ORDER_IDS_FOR_PRINT:
      return {
        ...state,
        packageNumbersForPrint: [],
        orderNumbersForPrint: [],
      }
    case ON_GET_BARCODE_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        barcodePDFList: action.payload,
      }
    case ON_GET_BARCODE_LIST_FAIL:
      return {
        ...state,
        isLoading: false,
        barcodePDFList: [],
      }
    case ON_LOAD_BARCODE_LIST:
      return {
        ...state,
        isLoading: true,
        barcodePDFList: [],
      }
    case CLEAR_BARCODE_LIST:
      return {
        ...state,
        barcodePDFList: [],
      }
    default:
      return state
  }
}

export default BarcodeReducer
