import React, { Component } from "react"
import { connect } from "react-redux"
import {
  onUpdateMessage,
  fetchAllStations,
  closeMessage,
} from "../../../actions"
import { Button } from "../../common/Button"
import { LabeledTextarea as TextArea } from "../../common/LabeledTextarea"
import Message from "../../common/Message"
import SelectStationField from "../../common/SelectField/SelectStationField"
import Title from "../../common/Title"
import "./style.scss"

class UpdateMessageScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentStation: {},
      stationsList: [],
      sendSMS: true,
    }
  }

  componentDidMount() {
    const { authenticated, history } = this.props
    if (!authenticated) {
      history.push("/")
    }
    this.props.fetchAllStations()
  }

  componentDidUpdate(prevProps) {
    const { stationsList } = this.props
    const { authenticated, history } = prevProps
    if (!authenticated) {
      history.push("/")
    }
    if (prevProps !== this.props) {
      this.selectStation()
    }
  }

  componentWillUnmount() {
    this.setState(this.state)
  }

  selectStation() {
    const { stationsList, selectedStation } = this.props
    if (stationsList) {
      var result = stationsList.find((obj) => {
        return obj.stationNumber === selectedStation
      })
      if (result) {
        this.setState({ stationsList: [result], currentStation: result })
        return
      }
    }
    this.setState({
      stationsList: stationsList,
      currentStation: stationsList[0],
    })
  }

  setValue(inputName, e) {
    const { stationsList } = this.props

    switch (inputName) {
      case "currentStation":
        this.setState({
          currentStation: stationsList.find(
            (el) => el.branchDisplayName === e.target.value
          ),
        })
        break
      case "stationsList":
        this.setState({ stationsList: e.target.value })
        break
      case "stationAddress":
        this.setState({
          currentStation: {
            ...this.state.currentStation,
            branchDisplayAddress: e.target.value,
          },
        })
        break
      default:
        break
    }
  }

  render() {
    let { currentStation, stationsList, sendSMS } = this.state
    const {
      onUpdateMessage,
      showMessage,
      isError,
      closeMessage,
      fetchAllStations,
    } = this.props

    return (
      <div>
        <Title text="שינוי כתובת בהודעת סמס ללקוח" />
        <div className="inputs">
          <SelectStationField
            options={stationsList}
            emptyOption={false}
            value={currentStation?.branchDisplayName}
            onChange={(e) => {
              this.setValue("currentStation", e)
            }}
          />
          <TextArea
            value={currentStation?.branchDisplayAddress}
            inputSize={400}
            onChange={(e) => {
              this.setValue("stationAddress", e)
            }}
          />
          <div className="checkbox">
            <input
              type="checkbox"
              name="sendSMS"
              id="sendSMS"
              checked={sendSMS}
              onChange={() => {
                this.setState({ sendSMS: !sendSMS })
              }}
            />
            <label htmlFor="sendSMS">שליחת הודעה לכל החבילות בעמדה</label>
          </div>
          <div className="buttons-wrapper">
            <Button
              show={true}
              text="עדכון"
              onClick={() => {
                onUpdateMessage(
                  currentStation.branchDisplayAddress,
                  sendSMS,
                  currentStation.stationId
                )
              }}
            />
            <Button onClick={(e) => this.props.history.goBack()}>חזור</Button>
          </div>
          <Message
            show={showMessage}
            isError={isError}
            successText="הכתובת השתנתה בהצלחה"
            errorText="אנא בדוק את הפרטים ונסה שוב"
            onClick={() => {
              closeMessage()
              fetchAllStations()
            }}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ users, station }) => {
  const { authenticated } = users
  const { stationsList, showMessage, isError, selectedStation } = station

  return {
    authenticated,
    stationsList,
    showMessage,
    isError,
    selectedStation,
  }
}

export default connect(mapStateToProps, {
  onUpdateMessage,
  fetchAllStations,
  closeMessage,
})(UpdateMessageScreen)
