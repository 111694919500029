import React, { useState } from "react"
import { connect } from "react-redux"
import Title from "../../common/Title"
import { Button } from "../../common/Button"
import Loader from "../../common/Loader"
import Select from "react-select"
import {
  fetchAllStations,
  fetchAllStationData,
  onCreateLockerToLocker,
  clearLockerToLocker,
} from "../../../actions"
import "./style.scss"
import { useEffect } from "react"
import NotificationModal from "../../common/NotificationModal"

const CreateLockerToLockerScreen = (props) => {
  const initialFormData = {
    externalOrderNumber: "",
    mobilePhone: "",
    firstName: "",
    lastName: "",
    station: "",
    stationsList: null,
    stationsFiltered: [],
  }
  const [formData, setFormData] = useState(initialFormData)
  const [formErrors, setFormErrors] = useState({})

  useEffect(() => {
    props.fetchAllStations()
    return () => {
      props.clearLockerToLocker()
    }
  }, [])

  useEffect(() => {
    if (props.stationsList?.length > 0) {
      props.fetchAllStationData(props.stationsList)
    }
  }, [props.stationsList])

  useEffect(() => {
    if (props.stationSelectList) {
    }
  }, [props.stationSelectList])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
    setFormErrors({ ...formErrors, [name]: "" })
  }

  const handleStationSelectChange = (selectedOption) => {
    setFormData({ ...formData, station: selectedOption })
    setFormErrors({ ...formErrors, station: "" })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const errors = {}
    if (!formData.mobilePhone) {
      errors.mobilePhone = "מספר טלפון לא תקין"
    }
    if (!formData.firstName) {
      errors.firstName = "שם פרטי לא תקין"
    }
    if (!formData.lastName) {
      errors.lastName = "שם משפחה לא תקין"
    }
    if (!formData.station) {
      errors.station = "אנא בחר עמדה"
    }

    if (Object.keys(errors).length === 0) {
      const order = {
        externalOrderNumber: formData.externalOrderNumber,
        toMobilePhone: formData.mobilePhone,
        toCustomerFirstName: formData.firstName,
        toCustomerLastName: formData.lastName,
        toStationNumber: formData.station.value,
      }
      props.onCreateLockerToLocker(order)
    } else {
      setFormErrors(errors)
    }
  }

  const onCloseMsg = () => {
    props.clearLockerToLocker()
    setFormData(initialFormData)
  }

  return (
    <div className="create-locker-to-locker">
      <Title text="יצירת משלוח חדש" />
      {props.isLoading ? (
        <Loader show={props.isLoading} />
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              id="externalOrderNumber"
              name="externalOrderNumber"
              value={formData.externalOrderNumber}
              onChange={(e) => handleInputChange(e)}
              placeholder="מספר חבילה"
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              id="mobilePhone"
              name="mobilePhone"
              value={formData.mobilePhone}
              onChange={(e) => handleInputChange(e)}
              placeholder="מספר טלפון"
            />
            {formErrors.mobilePhone && (
              <div className="error-message">{formErrors.mobilePhone}</div>
            )}
          </div>
          <div className="form-group">
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={(e) => handleInputChange(e)}
              placeholder="שם פרטי"
            />
            {formErrors.firstName && (
              <div className="error-message">{formErrors.firstName}</div>
            )}
          </div>
          <div className="form-group">
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={(e) => handleInputChange(e)}
              placeholder="שם משפחה"
            />
            {formErrors.lastName && (
              <div className="error-message">{formErrors.lastName}</div>
            )}
          </div>
          <div className="form-group">
            <Select
              id="stationsList"
              name="stationsList"
              value={formData.station}
              onChange={(itm) => handleStationSelectChange(itm)}
              options={props.stationSelectList}
              placeholder="עמדה"
            />
            {formErrors.station && (
              <div className="error-message">{formErrors.station}</div>
            )}
          </div>
          <div className="btn-holder">
            <Button type="submit">צור הזמנה</Button>
            <Button onClick={() => props.history.goBack()}> חזור</Button>
          </div>
        </form>
      )}
      <NotificationModal
        show={props.createLockerToLockerSuccess}
        title={"יצירת הזמנה"}
        text={"הזמנה נוצרה בהצלחה"}
        onOkClick={() => onCloseMsg()}
      />
      <NotificationModal
        show={props.createLockerToLockerFail}
        title={"יצירת הזמנה "}
        text={"יצירת הזמנה נכשלה"}
        onOkClick={() => onCloseMsg()}
      />
    </div>
  )
}

const mapStateToProps = ({ station, orders }) => {
  const {
    stationsList,
    stationsItemSelectList,
    selectedStation,
    stationSelectList,
    isLoading,
  } = station
  const { createLockerToLockerSuccess, createLockerToLockerFail } = orders
  return {
    isLoading,
    stationsList,
    stationsItemSelectList,
    selectedStation,
    stationSelectList,
    createLockerToLockerSuccess,
    createLockerToLockerFail,
  }
}

export default connect(mapStateToProps, {
  fetchAllStations,
  fetchAllStationData,
  onCreateLockerToLocker,
  clearLockerToLocker,
})(CreateLockerToLockerScreen)
