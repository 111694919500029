import {
  ON_GET_DELIVERY_ISRAEL_POST_SUCCESS,
  ON_GET_DELIVERY_ISRAEL_POST_FAIL,
  ON_CLEAR_DELIVERY_ISRAEL_POST,
} from "../actions/types"

const INIT_STATE = {
  israelPostDelivery: null,
  israelPostDeliverySuccess: false,
  israelPostDeliveryFail: false,
}

const IsraelPostReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_GET_DELIVERY_ISRAEL_POST_SUCCESS:
      return {
        ...state,
        israelPostDelivery: action.payload,
        israelPostDeliverySuccess: true,
      }
    case ON_GET_DELIVERY_ISRAEL_POST_FAIL:
      return {
        ...state,
        israelPostDelivery: null,
        israelPostDeliveryFail: true,
      }
    case ON_CLEAR_DELIVERY_ISRAEL_POST:
      return {
        ...state,
        israelPostDelivery: null,
        israelPostDeliverySuccess: false,
        israelPostDeliveryFail: false,
      }
    default:
      return state
  }
}

export default IsraelPostReducer
