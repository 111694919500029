import React, { Component } from 'react';
import './style.scss';


class Title extends Component {
    constructor(props) {
        super(props);
        
    }
    render(){
        const {text, ...props } = this.props;
        return(
            <h1>{ text} </h1>
        );
    }
}

export default Title;