import React, { useState } from "react"
import "./style.css"
import Select from "react-select"
import makeAnimated from "react-select/animated"

const animatedComponents = makeAnimated()

const SelectMultiAutocomplete = ({
  placeholder,
  items,
  onChangeHandler,
  value,
  extraClassName,
  isMulti,
  closeOnSelect,
  defaultValue,
}) => {
  const [selectedOption, setSelectedOption] = useState(null)

  const onchangeSelect = (item) => {
    setSelectedOption(item)
    if (item) {
      onChangeHandler(item)
    } else {
      onChangeHandler(null)
    }
  }

  let options = []

  if (
    !items ||
    items === [] ||
    (items.length > 0 &&
      items[0].label !== undefined &&
      items[0].value !== undefined)
  ) {
    options = items
  } else if (
    items.length > 0 &&
    (items[0].label === undefined || items[0].value === undefined)
  ) {
    options = items.map((itm, index) => {
      return { value: index, label: itm }
    })
  } else {
    let itemsObj = Object.assign(items)
    options = []
    for (var property in itemsObj) {
      options[Number(property)] = {
        value: Number(property),
        label: itemsObj[property],
      }
    }
  }

  return (
    <div className={"multi-autocomplete-wrapper " + extraClassName}>
      <Select
        classNamePrefix="multi-select"
        closeMenuOnSelect={closeOnSelect ? true : false}
        value={value}
        defaultValue={defaultValue}
        placeholder={placeholder}
        isClearable={true}
        options={options}
        components={animatedComponents}
        onChange={onchangeSelect}
        isMulti={isMulti}
      />
    </div>
  )
}

export { SelectMultiAutocomplete }
