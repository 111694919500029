import React, { useMemo, useState } from "react"
import { connect } from "react-redux"
import TableContainer from "../../../common/TableContainer"
import { Container } from "reactstrap"
import { setEmtyIfNull } from "../../../../utils/getIfEmpty"
import { ButtonRectangle } from "../../../common/ButtonRectangle"
import "./style.scss"

const UsersList = (props) => {
  let [data] = useState([])
  const { usersListArr, onClickDelete, onClickResetPass } = props

  const formateUser = (order) => {
    const { firstName, lastName, mobilePhone, id } = order

    return {
      id: id,
      mobilePhone: setEmtyIfNull(mobilePhone),
      firstName: setEmtyIfNull(firstName),
      lastName: setEmtyIfNull(lastName),
    }
  }

  const formattedList = usersListArr
    ? usersListArr.map((order) => formateUser(order))
    : []
  data = useMemo(() => formattedList, [usersListArr])

  const columns = useMemo(() => {
    const result = [
      {
        Header: "שם פרטי",
        accessor: "firstName",
      },
      {
        Header: "שם משפחה",
        accessor: "lastName",
      },
      {
        Header: "נייד",
        accessor: "mobilePhone",
      },
      {
        Header: () => null,
        id: "pass",
        Cell: (props) => (
          <ButtonRectangle
            extraClass="btn-reset-pass"
            onClick={() => onClickResetPass(props.cell.row.original.id)}
          >
            איפוס סיסמה
          </ButtonRectangle>
        ),
      },
      {
        Header: () => null,
        id: "key",
        Cell: (props) => (
          <ButtonRectangle
            extraClass="btn-delete"
            onClick={() => onClickDelete(props.cell.row.original.id)}
          >
            מחיקה
          </ButtonRectangle>
        ),
      },
    ]
    return result
  }, [])

  return (
    <div className="user-list-wrapper">
      <Container className="user-list">
        <TableContainer columns={columns} data={data} />
      </Container>
    </div>
  )
}

const mapStateToProps = ({ users }) => {
  const { usersListArr } = users
  return {
    usersListArr,
  }
}

export default connect(mapStateToProps)(UsersList)
