import React, { Component } from "react"
import { connect } from "react-redux"
import Title from "../../common/Title"
import InputField from "../../common/InputField"
import { Button } from "../../common/Button"
import Loader from "../../common/Loader"
import SelectField from "../../common/SelectField"
import Message from "../../common/Message"
import OrderHistoryModal from "../../common/OrderHistoryModal"
import { getFormatedDate } from "../../../utils/FormatedDate"
import {
  onStartLoading,
  onUpdateOrder,
  setSelectedOrder,
  fetchOrderStatuses,
  closeMessage,
  getOrderDetails,
} from "../../../actions"

import "./style.scss"

class UpdateOrderScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      order: null,
      isShowHistory: false,
    }
  }

  componentDidMount() {
    const { authenticated, history } = this.props
    if (!authenticated) {
      history.push("/")
    }
    this.setState({ order: this.props.selectedOrder })
    this.props.fetchOrderStatuses()
  }

  componentWillReceiveProps(newProps) {
    const { authenticated, history, orderDetails, order } = newProps
    if (!authenticated) {
      history.push("/")
    }
    this.setState({ order: order })
    if (orderDetails) {
      this.setState({ isShowHistory: true })
    }
  }

  onClickUpdateOrder() {
    this.props.onStartLoading()
    const { order } = this.props
    this.props.onUpdateOrder(order)
  }

  onUpdateField(fieldName, value) {
    const { order } = this.props
    let updatedOrder = order
    updatedOrder[fieldName] = value
    this.setState({ order: updatedOrder })
    this.props.setSelectedOrder(updatedOrder)
  }

  onMessageCkickOk() {
    this.props.closeMessage()
    this.props.history.push("/OrdersListScreen")
  }

  showOrderHistory() {
    const { order } = this.props
    this.props.getOrderDetails(order.id)
  }

  closeOrderHistory() {
    this.setState({ isShowHistory: false })
  }

  render() {
    const { isLoading, showMessage, isError, orderDetails } = this.props
    const { order } = this.state
    if (isLoading || order === null) {
      return (
        <div>
          <Title text="עדכון הזמנה" />
          <Loader show={isLoading} />
          <Message
            show={showMessage}
            isError={isError}
            successText="ההזמנה עודכנה בהצלחה"
            errorText="ההזמנה לא הודכנה"
            onClick={(e) => this.onMessageCkickOk(e)}
          />
        </div>
      )
    }

    const { ordersStatusesArr } = this.props
    const selectOptions = []
    const currentOrderStatusIndex = order.orderStatus // getStatusIndexFromARR(ordersStatusesArr, order.orderStatus);
    selectOptions[currentOrderStatusIndex] =
      ordersStatusesArr[currentOrderStatusIndex] //order.orderStatus;

    selectOptions[4] = ordersStatusesArr[4]
    selectOptions[5] = ordersStatusesArr[5]
    selectOptions[6] = ordersStatusesArr[6]
    selectOptions[12] = ordersStatusesArr[12]
    selectOptions[13] = ordersStatusesArr[13]

    return (
      <div>
        <Title text="עדכון הזמנה" />
        <div onClick={() => this.showOrderHistory()} className="btn-history">
          היסטוריה של הזמנה
        </div>
        <InputField
          show={true}
          value={order.orderNumber}
          labelText="מספר הזמנה"
          disabled
        />
        <InputField
          show={true}
          value={order.packageNumber}
          labelText="מספר חבילה"
          disabled
        />
        <InputField
          show={true}
          value={order.mobilePhone}
          labelText="מספר טלפון"
          disabled
        />
        <InputField
          show={true}
          value={order.firstName}
          labelText="שם פרטי"
          disabled
        />
        <InputField
          show={true}
          value={order.lastName}
          labelText="שם משפחה"
          disabled
        />
        <SelectField
          show={true}
          value={order.orderStatus}
          labelText="סטטוס הזמנה"
          options={selectOptions}
          onChange={(e) => this.onUpdateField("orderStatus", e.target.value)}
          selectedIndex={currentOrderStatusIndex}
          emptyOption={false}
        />
        <div className="buttons-wrapper">
          <Button onClick={(e) => this.onClickUpdateOrder()}>
            עדכן הזמנה{" "}
          </Button>
          <Button onClick={(e) => this.props.history.goBack()}>חזור</Button>
        </div>

        <Loader show={isLoading} />

        <Message
          show={showMessage}
          isError={isError}
          successText="ההזמנה עודכנה בהצלחה"
          errorText="ההזמנה לא עודכנה"
          onClick={(e) => this.onMessageCkickOk(e)}
        />
        <OrderHistoryModal
          isShow={this.state.isShowHistory}
          order={orderDetails}
          setViewHistory={() => this.closeOrderHistory()}
          statuses={ordersStatusesArr}
          getFormatedDate={getFormatedDate}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ users, orders, orderStatuses }) => {
  const { authenticated } = users
  const { isLoading, order, showMessage, isError, orderDetails } = orders
  const { ordersStatusesArr } = orderStatuses

  return {
    authenticated,
    isLoading,
    showMessage,
    isError,
    order,
    ordersStatusesArr,
    orderDetails,
  }
}

export default connect(mapStateToProps, {
  onStartLoading,
  onUpdateOrder,
  setSelectedOrder,
  fetchOrderStatuses,
  closeMessage,
  getOrderDetails,
})(UpdateOrderScreen)
