import React, { Component } from "react"
import { connect } from "react-redux"
import Title from "../../common/Title"
import Loader from "../../common/Loader"
import {
  fetchAllUsers,
  deleteUser,
  clearRequestData,
  resetPassword,
} from "../../../actions"
import "./style.scss"
import UsersList from "./UsersList"
import YesNoModal from "../../common/YesNoModal"
import NotificationModal from "../../common/NotificationModal"

class UsersListScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showDeleteUserModal: false,
      showResetPasswordModal: false,
      userIdToDelete: null,
      userIdToResetPassowrd: null,
    }
  }

  componentDidMount() {
    const { authenticated, history } = this.props
    if (!authenticated) {
      history.push("/")
    }
    this.props.fetchAllUsers()
  }

  componentWillReceiveProps(newProps) {
    const { authenticated, history, userDeletedSuccess } = newProps
    if (!authenticated) {
      history.push("/")
    }
    if (userDeletedSuccess) {
      this.props.clearRequestData()
      this.props.fetchAllUsers()
    }
  }

  onDeleteUserClick(userId) {
    this.setState({ showDeleteUserModal: true, userIdToDelete: userId })
  }

  onResetPasswordClick(userId) {
    this.setState({
      showResetPasswordModal: true,
      userIdToResetPassowrd: userId,
    })
  }

  onNewUserClick() {
    this.props.history.push("/NewUser")
  }

  confirmDeleteUser() {
    this.props.deleteUser(this.state.userIdToDelete)
    this.setState({ showDeleteUserModal: false })
  }

  confirmResetPassword() {
    this.props.resetPassword(this.state.userIdToResetPassowrd)
    this.setState({ showResetPasswordModal: false })
  }

  render() {
    const {
      isLoading,
      userDeleteFail,
      isPasswordResetSucces,
      isPasswordResetFail,
    } = this.props
    const { showDeleteUserModal, showResetPasswordModal } = this.state

    if (isLoading) {
      return (
        <div>
          <Title text="רשימת משתמשים" />
          <Loader show={isLoading} />
        </div>
      )
    }

    return (
      <div className="user-list">
        <Title text="רשימת משתמשים" />
        <div className="btn-new-user" onClick={() => this.onNewUserClick()}>
          יצירת משתמש חדש
        </div>
        <UsersList
          onClickDelete={(userId) => this.onDeleteUserClick(userId)}
          onClickResetPass={(userId) => this.onResetPasswordClick(userId)}
        />
        <YesNoModal
          show={showDeleteUserModal}
          title={"ניהול משתמשים"}
          text={"?האם אתה בטוח שברצונך למחוק משתמש זה"}
          onYesClick={() => this.confirmDeleteUser()}
          onNoClick={() => this.setState({ showDeleteUserModal: false })}
        />
        <NotificationModal
          show={userDeleteFail}
          title={"ניהול משתמשים"}
          text={"כישלון במחיקת משתמש"}
          onOkClick={() => this.props.clearRequestData()}
        />
        <NotificationModal
          show={isPasswordResetSucces}
          title={"ניהול משתמשים"}
          text={".איפוס סיסמה עבר בהצלחה, סיסמה חדשה נשלחה בסמס"}
          onOkClick={() => this.props.clearRequestData()}
        />
        <NotificationModal
          show={isPasswordResetFail}
          title={"ניהול משתמשים"}
          text={"איפוס סיסמה נכשל"}
          onOkClick={() => this.props.clearRequestData()}
        />
        <YesNoModal
          show={showResetPasswordModal}
          title={"ניהול משתמשים"}
          text={"?האם אתה בטוח שברצונך לאפס סיסמה למשתמש הזה"}
          onYesClick={() => this.confirmResetPassword()}
          onNoClick={() => this.setState({ showResetPasswordModal: false })}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ users }) => {
  const {
    authenticated,
    usersListArr,
    isLoading,
    userDeletedSuccess,
    userDeleteFail,
    isPasswordResetSucces,
    isPasswordResetFail,
  } = users

  return {
    authenticated,
    isLoading,
    usersListArr,
    userDeletedSuccess,
    userDeleteFail,
    isPasswordResetSucces,
    isPasswordResetFail,
  }
}

export default connect(mapStateToProps, {
  fetchAllUsers,
  deleteUser,
  resetPassword,
  clearRequestData,
})(UsersListScreen)
