export const stations_RecalcFilteredListOutOfLatestState = (state) => {
  const { stations, stationsLocalSearch } = state
  const { searchText, filtererActiveStations } = stationsLocalSearch

  const selectedStationNumbers = filtererActiveStations.map(
    ({ value }) => value
  )
  let stationsFilteredListArr = stations.filter(
    ({ stationNumber }) =>
      selectedStationNumbers.includes(stationNumber) ||
      selectedStationNumbers.length === 0
  )

  if (searchText && searchText.length > 0) {
    stationsFilteredListArr = stations_FilterBySearchText(
      stationsFilteredListArr,
      searchText
    )
  }

  return {
    ...state,
    stationsLocalSearch: {
      ...stationsLocalSearch,
      stationsFilteredListArr,
    },
  }
}

export const stations_FilterBySearchText = (list, searchText) => {
  const stationsFilteredListArr = list.filter((station) => {
    const { stationNumber, branchName, branchAddress, lockersNumber } = station

    return (
      searchText === "" ||
      stationNumber.toString().includes(searchText) ||
      (lockersNumber && lockersNumber.toString().includes(searchText)) ||
      (branchName &&
        branchName.toLowerCase().includes(searchText.toLowerCase())) ||
      (branchAddress &&
        branchAddress.toLowerCase().includes(searchText.toLowerCase()))
    )
  })

  return stationsFilteredListArr
}

export const stations_HandleFilteredActiveStationsUpdate = (
  state,
  choosedStationsItems
) => {
  let { stationsLocalSearch } = state

  let newState = {
    ...state,
    stationsLocalSearch: {
      ...stationsLocalSearch,
      filtererActiveStations: choosedStationsItems,
      page: 1,
    },
  }

  return stations_RecalcFilteredListOutOfLatestState(newState)
}
