import React from "react"
import Modal from "../Modal"
import { ButtonRectangle } from "../ButtonRectangle"
import "./style.css"

const YesNoModal = ({ show, title, text, onYesClick, onNoClick }) => {
  if (!show) {
    return <noscript />
  }

  return (
    <Modal extraClass="yesno-modal-wrap">
      <div className="yesno-modal-container">
        <div className="yesno-modal-content-wrapper">
          {text}
          <div className="yesno-modal-btns-wrapper">
            <ButtonRectangle
              extraClass="yesno-modal-button"
              onClick={(e) => onYesClick()}
            >
              אישור
            </ButtonRectangle>
            <ButtonRectangle
              extraClass="yesno-modal-button"
              onClick={(e) => onNoClick()}
            >
              ביטול
            </ButtonRectangle>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default YesNoModal
