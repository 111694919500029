import React, { Component } from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import store from "../../../store"

import "./style.css"

class Modal extends Component {
  //!!!!!!!! TODO USE REACT MODAL LIBRARY INSTEAD!!!!!!!!!!!!

  componentDidMount() {
    this.modalTarget = document.createElement("div")
    this.modalTarget.className = `modal ${this.props.extraClass}`
    document.body.appendChild(this.modalTarget)
    this._render()
  }

  componentWillUpdate() {
    this._render()
  }

  componentWillUnmount() {
    document.body.removeChild(this.modalTarget)
  }

  _render() {
    createRoot(this.modalTarget).render(
      <Provider store={store}>
        <div>{this.props.children}</div>
      </Provider>
    )
  }

  render() {
    // NOT RENDERING WORKAROUND..!:
    try {
      this._render()
    } catch (e) {}

    return <noscript />
  }
}

export default Modal
