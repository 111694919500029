import React, { Component } from "react"
import { connect } from "react-redux"
import Menu from "../common/Menu"
import { AppVersion } from "../../utils"
import logo from "../../assets/images/logo.png"
import { setFullMenu } from "../../actions"
import "./style.scss"

class AppLayout extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { userData } = this.props
    return (
      <div className="app-wrapper">
        <div className="header-wrapper">
          <div className="logo-wrapper">
            <img src={logo} />
          </div>
          <div className="app-version">{AppVersion}</div>
          {userData && userData.firstName && userData.lastName ? (
            <div className="welcome">
              שלום {userData.firstName + " " + userData.lastName}
            </div>
          ) : null}

          <Menu
            accountType={userData && userData.accountType}
            isAllowCreateOrder={userData && userData.isAllowCreateOrder}
            setFullMenu={() => this.props.setFullMenu()}
          />
        </div>

        <div className="content-wrapper">
          <div className="content">{this.props.children}</div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ users }) => {
  const { userData } = users
  return {
    userData,
  }
}
export default connect(mapStateToProps, { setFullMenu })(AppLayout)

//export default AppLayout;
