import axios from "axios"
import api from "../api/BaseApi"

export const downloadPackageDebitReport = (selectedMonth, selectedYear) => {
  return () => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
      responseType: "blob",
    }
    axios
      .get(api.getPackageDebitReport(selectedMonth, selectedYear), options)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "PackageDebitReport.xlsx")
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch((error) =>
        console.log("downloadPackageDebitReport Error: " + error)
      )
  }
}
