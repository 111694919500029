import React from "react"

import "./style.scss"

const SelectField = ({
  show = true,
  labelText,
  emptyOption = true,
  selectedIndex = undefined,
  options,
  value,
  onChange,
}) => {
  const renderEmptyOption = (emptyOption) => {
    if (!emptyOption) {
      return
    }
    return (
      <option key="" value="">
        ללא בחירה
      </option>
    )
  }

  const renderOptions = (options, selectedIndex) => {
    if (!options) {
      return null
    }
    return Object.keys(options).map((key) => (
      <option
        key={key}
        value={key}
        name={key}
        defaultValue={setDefaultText(key, selectedIndex)}
        disabled={isValid(selectedIndex, key) ? false : true}
        style={
          isValid(selectedIndex, key)
            ? { fontWeight: "600" }
            : { color: "rgb(215, 220, 224)" }
        }
      >
        {options[key]}
      </option>
    ))
  }

  const setDefaultText = (key, selectedIndex) => {
    if (key === selectedIndex) {
      return true
    }
    return false
  }

  if (!show) {
    return <></>
  }

  const isValid = (currValue, optionIdx) => {
    if (
      (currValue == 3 && optionIdx != 6) ||
      currValue == 12 ||
      currValue == 6 ||
      currValue == 5 ||
      (currValue == 4 && optionIdx != 13 && optionIdx != 5) ||
      (currValue == 13 && optionIdx != 12 && optionIdx != 5) ||
      (currValue == 13 && optionIdx != 5)
    ) {
      return false
    }
    return true
  }

  return (
    <div className="component-wrapper select-field">
      <div className="select-wrapper">
        <label>{labelText}</label>
        <select className="ss-input" value={value} onChange={onChange}>
          {renderEmptyOption(emptyOption)}
          {renderOptions(options, value)}
        </select>
      </div>
    </div>
  )
}

export default SelectField
