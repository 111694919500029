import React from "react";

import "./style.scss";

const LabeledTextarea = ({
  labelText,
  inputSize,
  disabled = false,
  ...props
}) => {
  return (
    <div className="ss-group">
      <label className="ss-label">{labelText}</label>
      <textarea
        className="ss-input"
        type="textarea"
        size={inputSize}
        {...props}
      >
        {props.value}
      </textarea>
    </div>
  );
};

export { LabeledTextarea };
